.navbar-1-1 .btn-fill {
  background-color: #0ec8f8;
  transition: 0.3s;
}

.navbar-1-1 .btn-fill:hover {
  background-color: #3ad8ff;
  transition: 0.3s;
}

.navbar-1-1 nav a.nav-link {
  color: gray;
  transition: 0.3s;
}

.navbar-1-1 nav a.nav-link:hover {
  color: white;
  font-weight: 500;
  transition: 0.3s;
}

.navbar-1-1 nav a.nav-link.active {
  color: white;
}

.navbar-1-1 #menu-toggle:checked + #menu,
.navbar-1-1 #menu-toggle:checked + #menu + #menu {
  display: block;
}
.content-2-3 .btn-outline {
  border: 1px solid #707092;
  color: #707092;
  transition: 0.2s;
}

.content-2-3 .btn-outline:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: 0.2s;
}

.content-2-3 .card-header {
  background-color: #292952;
  border: 1px solid #4c4c6d;
}

.content-2-3 .btn-fill {
  background-color: #524eee;
  transition: 0.2s;
}

.content-2-3 .btn-fill:hover {
  --tw-shadow: inset 0 0px 25px 0 rgba(20, 20, 50, 0.7);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: 0.2s;
}

.content-2-3 .text-purple {
  color: #707092;
}

.carousel .thumbs-wrapper {
  display: none;
}

.slider-container {
  width: 100%;
  height: 100%;
  
  /* Add this */
  position:fixed;
  top:0;
  left:0;
  
  }
